export const whatIsStaking = {
  order: 3,
  name: "What is staking?",
  keywords: "stake",
  subcategory: "Earn revenue",
  markdown: `# What is staking?

  In Tower Hunt, it takes money (and good data!) to make money. When you want to make a contribution to the {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}public data layer{% /inlineRouterLink %}, you set aside a small amount of money in the form of data credits. This is called *staking*, and it is conceptually similar to the ante in a game of poker or the collateral for a loan, but on a much smaller scale. Importantly, staking is actually an opportunity to {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}earn one-time revenue{% /inlineRouterLink %}. For active contributors, staking is a big part of the upside that Tower Hunt offers. Read on to learn about the mechanics.

  ## When staking occurs

  In Tower Hunt, two actions trigger staking:

  1. A {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} enters the {% inlineRouterLink articleId="understand-how-datapoints-become-public" sectionName="What to expect" %}validation pipeline{% /inlineRouterLink %}.
  2. A {% inlineRouterLink articleId="vote-on-validations" sectionName="What to expect" %}validation vote is submitted{% /inlineRouterLink %}.

  {% callout type="caution" %}
  **Important:** Tower Hunt has the right to {% inlineRouterLink articleId="understand-how-datapoints-become-public" sectionName="Publish" %}publish{% /inlineRouterLink %} a {% inlineRouterLink articleId="the-role-of-companies" %}company{% /inlineRouterLink %} or {% inlineRouterLink articleId="the-role-of-contacts" %}contact{% /inlineRouterLink %} that you create -- just its name, not any other data you’ve associated -- to prevent duplicates in the public layer. If Tower Hunt does this, you are responsible for the associated staking costs since you added the data. Of course, you also receive any {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}earnings{% /inlineRouterLink %} related to the company or contact if it is approved.
  {% /callout %}

  ## How many credits are staked

  In either case, the size of the stake is influenced by three factors:

  1. The {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="How much does it cost" %}price of the datapoint{% /inlineRouterLink %}: more expensive datapoints require a larger stake.
  2. Your {% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}reputation{% /inlineRouterLink %}: higher reputations require lower stakes.
  3. Whether you are an {% inlineRouterLink articleId="create-a-datapoint" %}originator{% /inlineRouterLink %} or a {% inlineRouterLink articleId="understand-validation" %}validator{% /inlineRouterLink %}: editing the database requires a larger stake than validating a datapoint.

  ## Staking outcomes

  What happens to your stake depends on the {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}validation outcome{% /inlineRouterLink %}:

  - Approval: your stake is returned to you along with {% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}a reward that increases with your reputation{% /inlineRouterLink %}.
  - Rejection: your stake is forfeited.`,
};
